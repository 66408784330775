import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { FaqSection } from "../components/pageShared";

/**
 * FaqPage
 */
const FaqPage = () => {
  const intl = useIntl();
  const _title = intl.formatMessage({
    id: `content.contentPages.Faq.title`,
  });

  return (
    <Layout>
      <SEO title={"FAQ"} />
      <FaqSection title="FAQ" />
    </Layout>
  );
};

export default FaqPage;
